<template>
<v-container class="display-block">
    <v-form ref="form" lazy-validation>
        <alert-message v-if="msg" :error="msg"></alert-message>
        <v-container>
            <v-layout wrap>
                <v-flex xs12 sm10>
                    <row-item label="Order No" :value="order.orderNo" v-if="order.orderNo"></row-item>
                </v-flex>
                <v-flex text-right xs12 sm2>
                    <row-item label="Date" :value="order.date|dateFormat"></row-item>
                </v-flex>
                <v-flex xs12 :class="$store.getters.vendor.enableSaleChallan?'sm6':'sm12'">
                    <auto-complete @addButtonClicked='showDialog' v-model="order.customer" :showAddNewButton="true" :items="customerList" title="displayName" subtitle="value" label="Customer*" uniqueKey="_id"></auto-complete>
                    <app-dialog :show="show" @close="show=!show">
                        <v-alert v-if="customerErrorMsg" :type="msgType">
                            <ul v-for="item in customerErrorMsg" :key="item.errors">
                                <li>{{item}}</li>
                            </ul>
                        </v-alert>
                        <v-form ref="signupForm" lazy-validation>
                            <v-row>
                                <v-text-field class="mx-3" v-model="user.firstName" :rules="firstNameRules" prepend-icon="mdi-account" label="FirstName*" />
                                <v-text-field class="mx-3" v-model="user.middleName" label="MiddleName" />
                                <v-text-field class="mx-3" v-model="user.lastName" :rules="lastNameRules" label="LastName*" />
                            </v-row>
                            <v-row>
                                <v-text-field class="mx-3" prepend-icon="mdi-cellphone" v-model="user.mobileNo" :rules="mobileNoRules" label="Mobile Number*" />
                                <v-text-field prepend-icon="mdi-email" v-model="user.email" :rules="emailRules" label="Email" type="Email address" />
                            </v-row>
                            <v-btn block :style="theme" @click="submitCustomer()">Save</v-btn>
                        </v-form>
                    </app-dialog>
                </v-flex>
                <v-flex xs12 :class="$store.getters.vendor.enableSaleChallan?'sm6':'sm12'">
                    <v-select :class="$vuetify.breakpoint.xsOnly?'':'pl-2'" :items="orderType" item-text="displayName" item-value="value" v-model="order.type" label="Order Type" v-if="$store.getters.vendor.enableSaleChallan"></v-select>
                </v-flex>
                <v-flex xs12 sm8 class="pr-2">
                    <auto-complete v-model="product" :items="productList" title="title" subtitle="code" label="Product*">
                        <template v-slot:default="item">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.item.title" :style="item.item.stock > 0 ? '' : 'color:red'"></v-list-item-title>
                                <v-list-item-subtitle>{{item.item.code}} | Available Stock: {{item.item.stock}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </auto-complete>
                </v-flex>
                <v-flex xs10 sm3 text-right>
                    <v-text-field type="number" v-model.number="quantity" label="Quantity"></v-text-field>
                </v-flex>
                <v-flex xs2 sm1 text-right>
                    <v-btn :style="themeInverted" @click="addItemInOrder()" outlined>Add</v-btn>
                </v-flex>
                <v-flex xs12>
                    <v-data-table :headers="headers" :items="order.items" class="elevation-1">
                        <template v-slot:item.amount="{item}">
                            {{item.price*item.quantity}}
                        </template>
                        <template v-slot:item.action="{item}">
                            <v-icon small @click="deleteItemFromOrder(item)">
                                mdi-close
                            </v-icon>
                        </template>
                        <template v-slot:footer>
                            <v-simple-table flat>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <td>SubTotal</td>
                                            <td class="text-right"><b>{{order.subTotal|amountFormat}}</b></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <v-layout wrap>
                                                    <v-flex xs6>Discount</v-flex>
                                                    <v-flex xs6>
                                                        <discount-card v-model="order.discount" :subTotal="order.subTotal" />
                                                    </v-flex>
                                                </v-layout>
                                            </td>
                                            <td class="text-right"><b>{{order.discount.amount|amountFormat}}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Tax : {{order.tax}}%</td>
                                            <td class="text-right"><b>{{order.taxAmount|amountFormat}}</b></td>
                                        </tr>
                                        <tr>
                                            <td v-if="!deliveryCharge" colspan="5" class="text-right">
                                                <v-btn outlined :style="themeInverted" x-small @click="deliveryCharge=!deliveryCharge">Add Delivery Charge</v-btn>
                                            </td>
                                            <td v-if="deliveryCharge">Delivery Charge : </td>
                                            <v-flex offset-sm8 offset-xs3>
                                                <td v-if="deliveryCharge" class="text-right">
                                                    <v-text-field @change="addDataToStorage()" clearable @click:clear="clearDeliveryCharge()" dense type="number" v-model.number="order.delivery.charge"></v-text-field>
                                                </td>
                                            </v-flex>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td class="text-right headline"><b>{{total|amountFormat}}</b></td>
                                        </tr>
                                    </thead>
                                </template>
                            </v-simple-table>
                        </template>
                    </v-data-table>
                    <v-flex offset-sm9>
                        <input-date label="Delivery Date" v-model="order.delivery.date"></input-date>
                    </v-flex>
                    <alert-message v-if="msg" :error="msg"></alert-message>
                </v-flex>
                <v-flex text-right class="mt-4" v-if="!order._id">
                    <v-btn class="mx-1" :style="theme" @click="setPaymentStatus(orderStatus.DRAFT_STATUS)">{{orderStatus.DRAFT_STATUS}}</v-btn>
                    <v-btn class="mx-1" :style="theme" @click="setPaymentStatus(paymentStatus.CREDIT)">Create Order</v-btn>
                    <v-btn class="mx-1" :style="themeInverted" outlined @click="cancel()">Cancel</v-btn>
                </v-flex>
                <v-flex text-right v-else>
                    <v-row style="float:right">
                        <order-action v-model="order" @clikedUpdateAction="removeDataFromStorage()"></order-action>
                        <v-btn v-if="getDataFromStorage()" :style="themeInverted" class="ma-2" outlined @click="cancel()">Remove From Cache</v-btn>
                    </v-row>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</v-container>
</template>

<script>
import RegistrationCard from "@/components/RegistrationCard.vue";
import appConstants from "@/utils/appConstants";
export default {
    components: {
        RegistrationCard
    },
    data() {
        return {
            order: {
                id: "",
                date: "",
                items: [],
                subTotal: 0,
                discount: {
                    amount: 0,
                    type: "",
                    percent: 0
                },
                taxAmount: 0,
                tax: 18,
                total: 0,
                pStatus: undefined,
                orderNo: "",
                delivery: {
                    date: "",
                    charge: 0
                },
                channel: "SHOP-COUNTER",
                type:'NORMAL'
            },
            show: false,
            headers: [
                { text: "Code", value: "code" },
                { text: "Product", value: "title" },
                { text: "Quantity", value: "quantity", align: "right" },
                { text: "Unit Price", value: "price", align: "right" },
                { text: "Amount", value: "amount", align: "right" },
                { text: "Action", value: "action", align: "right" }
            ],
            product: {},
            quantity: 1,
            productList: [],
            customerList: [],
            customerErrorMsg: "",
            user: this.setDefaultUser(),
            deliveryCharge: false,
            orderType:[{displayName:'Normal',value:'NORMAL'},{displayName:'Sale Challan',value:'SALE_CHALLAN'}]
        };
    },
    computed: {
        total() {
            if (this.order.items && this.order.items.length > 0) {
                return this.calculateTotal(this.order);
            }
            return 0;
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        addItemInOrder() {
            let index = this.order.items.findIndex(
                rec => rec._id == this.product._id
            );
            if (index > -1) {
                let prod = this.order.items[index];
                prod.quantity += this.quantity;
                this.order.items.splice(index, 1, prod);
            } else {
                this.order.items.push(
                    this.convertProductToOrderItem(this.product, this.quantity)
                );
            }
            this.addDataToStorage()
            this.quantity = 1;
            this.product = undefined;
        },
        deleteItemFromOrder(item) {
            const index = this.order.items.indexOf(item);
            this.order.items.splice(index, 1);
            this.order.subTotal = 0;
            if (this.order.items.length > 0)
                this.addDataToStorage()
            else
                this.removeDataFromStorage()
        },
        setPaymentStatus(payType) {
            this.order.pStatus = payType;
            this.order.action = payType;
            if (this.checkDeliveryDate(this.order.delivery.date))
                this.submit();
        },
        async initComponent() {
            this.id = this.$route.params.id;
            this.order.tax = this.$store.getters.vendor.taxPercent ? this.$store.getters.vendor.taxPercent : 0;
            this.order.vendorId = this.getUserProfile().vendorId;
            let products= await this.getItem(appConstants.PRODUCTS_API+'?size=-1');
            this.productList=products.data 
            this.order.delivery.date = this.setDeliveryDate()
            this.customerList = await this.getItem(
                appConstants.CUSTOMERS_API + "/refList"
            );
            if (this.id != 0) {
                this.order = await this.getItem(
                    appConstants.ORDERS_API + "/" + this.id
                );
                this.deliveryCharge = this.order.delivery.charge > 0
            } else if (this.getDataFromStorage() != null) {
                this.order = this.getDataFromStorage()
                this.order.date = new Date();
                this.deliveryCharge = this.order.delivery.charge > 0
            } else {
                this.order.date = new Date();
            }
        },
        submit() {
            if (this.$refs.form.validate()) {
                if (this.id != 0) {
                    this.updateItem();
                } else {
                    this.saveItem();
                }
            }
        },
        async saveItem() {
            try {
                this.order = await this.postItem(appConstants.ORDERS_API, this.order);
                this.removeDataFromStorage()
                this.$router.push({ name: "Orders" });
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data
            }
        },
        async updateItem() {
            try {
                await this.putItem(
                    appConstants.ORDERS_API + "/" + this.order._id,
                    this.order
                );
                this.$router.push({ name: "Orders" });
            } catch (error) {
                this.handleError(error);
                this.msg = error.response.data
            }
        },
        showDialog() {
            this.show = !this.show;
        },
        updateCustomer() {
            this.order.customer = this.convertToUserRefModel(this.user);
            this.customerList.push(this.order.customer);
            this.showDialog();
        },
        async submitCustomer() {
            this.customerErrorMsg = ""
            if (this.$refs.signupForm.validate()) {
                try {
                    this.user.vendorId = this.$store.getters.vendor._id;
                    this.user = await this.postItem(appConstants.CUSTOMERS_API, this.user);
                    this.updateCustomer();
                    this.user = this.setDefaultUser()
                    alert("Customer Added");
                } catch (error) {
                    this.customerErrorMsg = error.response.data
                    this.msgType = "error"
                }
            }
        },
        setDefaultUser() {
            return {
                firstName: "",
                middleName: "",
                lastName: "",
                mobileNo: "",
                email: "",
                vendorId: ""
            }
        },
        cancel() {
            this.removeDataFromStorage()
            this.$router.go(-1)
        },
        addDataToStorage() {
            localStorage.setItem(this.$store.getters.vendor._id + "-current-order", JSON.stringify(this.order))
        },
        removeDataFromStorage() {
            let vendorId=this.order.data?.vendorId||this.order.vendorId
            localStorage.removeItem(vendorId + "-current-order");
        },
        getDataFromStorage() {
            return JSON.parse(localStorage.getItem(this.order.vendorId + "-current-order"))
        },
        clearDeliveryCharge() {
            this.deliveryCharge = !this.deliveryCharge
            this.order.delivery.charge = 0
        }
    },
};
</script>

<style scoped>
</style>
